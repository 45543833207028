<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import SharedHeadline from "~/components/shared/SharedHeadline.vue";
import SharedTopline from "~/components/shared/SharedTopline.vue";

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const { headlineText, headlineType, toplineText, toplineType, Rows } = props.content.data

</script>
<template>
  <section class="">
    <div class="mb-10">
      <div class="container px-0 xl:px-5">
        <SharedTopline :headline="toplineText" :headlineType="toplineType"></SharedTopline>
        <SharedHeadline :headline="headlineText" :headlineType="headlineType"></SharedHeadline>
      </div>
    </div>
    <div class="">
      <div class="container max-w-[90rem] laptop:px-10">
        <div class="flex flex-col gap-y-10 laptop:gap-y-5 prose-figure:min-h-[15rem] prose-figure:flex">
          <template v-for="row in Rows">
            <div class="flex flex-wrap gap-y-8 items-center -mx-15">
              <div class="w-full md:w-2/4 px-0 lg:px-15">
                <figure class="rounded-10">
                  <NuxtImg format="webp" loading="lazy" width="600" v-if="row.rowImage && row.rowImage.media" :src="row.rowImage.media.url" alt="" class="w-full object-cover rounded-10" />
                </figure>
              </div>
              <div class="w-full md:w-2/4 px-0 lg:px-4 lg:pr-32">
                <h3 class="font-bold text-2xl mb-7" v-html="row.rowTitle"></h3>
                <div class="mb-4 apply-css" v-html="row.rowText"></div>
                <SharedButton v-if="row.rowLink"  :button="row.rowLink" :class="['btn-outline btn-sm', 'w-auto']" :has-arrow="true"  ></SharedButton>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.apply-css ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

.apply-css ul li {
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 0.75rem;
  line-height: 1.25;
}

.apply-css ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #4A4A49; /* You can change this color to match your theme */
  border-radius: 50%;
  transform: translateY(-25%);
}

.apply-css ul li:last-child {
  margin-bottom: 0;
}
</style>
